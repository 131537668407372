import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material"
import { Article } from "@spinning/entities"
import React from "react"
import { Link } from "react-router-dom"

type Props = { articles: Article[] | null }

const ArticleCard: React.FC<Props> = ({ articles }) => {
    return (
        <>
            {(articles || []).map(article => (
                <Grid item xs={6} key={article.id} sx={{ minWidth: 345, minHeight: 200 }}>
                    <CardActionArea sx={{ borderRadius: 3, m: 1 }} component={Link} to={`/article/${article.id}`}>
                        <Card
                            sx={{
                                borderRadius: 3,
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}
                            elevation={2}
                            square={false}
                        >
                            <CardContent sx={{ alignItems: "center" }}>
                                <Typography
                                    gutterBottom
                                    component="p"
                                    fontSize="10px"
                                    color="primary"
                                    variant="subtitle1"
                                >
                                    {article.title}
                                </Typography>
                                <Typography variant="h6" color="text.secondary" width="88%">
                                    {article.subtitle}
                                </Typography>
                            </CardContent>

                            <CardActions />

                            <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                                <Typography variant="subtitle1" color="primary">
                                    Ouvrir
                                </Typography>
                                <ArrowForwardIcon sx={{ ml: 1 }} color="primary" />
                            </CardActions>
                        </Card>
                    </CardActionArea>
                </Grid>
            ))}
        </>
    )
}

export default ArticleCard
