import { FormControl, MenuItem } from "@mui/material"
import Box from "@mui/material/Box"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Article } from "@spinning/entities"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, Link as RouterLink, useLocation, useParams } from "react-router-dom"
import { setArticle } from "../article/state/articleReducer"
import { setIsActive, setIsSuccess, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import { useApi } from "../features/UseApi"
import Loader from "../loader/Loader"
import Logo from "./Logo"

const NavBarLeftContainer = React.memo(() => {
    const { articleId } = useParams<{ articleId: string }>()
    const [articleIdSelected, setArticleIdSelected] = useState(articleId)

    const dispatch = useDispatch()
    const location = useLocation()
    const isHome = location.pathname === "/"

    const { data: articles, isLoading, error } = useApi<Article[]>("articles")

    if (error) {
        dispatch(setErrorMessage(error.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
        dispatch(setIsSuccess(false))
    }

    const handleChange = (event: SelectChangeEvent): void => {
        setArticleIdSelected(event.target.value)
        if (articles !== null) {
            const articleSelected = articles.find(article => article.id === Number(articleIdSelected))
            if (articleSelected !== undefined) dispatch(setArticle(articleSelected))
        }
    }

    const dispatchCurrentArticle = (): void => {
        if (articles !== null) {
            const articleSelected = articles.find(article => article.id === Number(articleId))
            if (articleSelected !== undefined) {
                dispatch(setArticle(articleSelected))
            }
        }
    }

    useEffect(() => {
        dispatchCurrentArticle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articles])

    return (
        <Box>
            <RouterLink to={"/"}>
                <Logo />
            </RouterLink>
            {!isHome && (
                <>
                    <FormControl sx={{ pl: 4 }}>
                        <Select onChange={handleChange} value={articleIdSelected} size="small" variant="outlined">
                            {(articles || []).map(article => (
                                <MenuItem value={article.id} key={article.id}>
                                    <Link
                                        to={`/article/${article.id}`}
                                        style={{ color: "#1ab6ff", textDecoration: "none" }}
                                    >
                                        {isLoading ? (
                                            <Loader size={20} />
                                        ) : (
                                            <>
                                                {article.title} "{article.subtitle}"
                                            </>
                                        )}
                                    </Link>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
        </Box>
    )
})

export default NavBarLeftContainer
