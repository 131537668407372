import { Box, Typography } from "@mui/material"
import { Content, Tag } from "@spinning/entities"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIsActive, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import { ContentType } from "../features/ContentType"
import { useApi } from "../features/UseApi"
import { AppState } from "../features/store"
import { ActivityState } from "./state/activityReducer"
import { setContent } from "./state/contentReducer"
import { TokenState } from "./state/tokenReducer"
import { TemplateNode, setTemplate } from "./state/templateReducer"

type Props = { tags: Tag[] | null }

const ArticleTemplate: React.FC<Props> = ({ tags }) => {
    const dispatch = useDispatch()

    const { data: contents, error } = useApi<Content[]>(`contents`)

    const selectedContent = useSelector<AppState, Content | null>(state => state.contentReducer.value)
    const tokenStates = useSelector<AppState, TokenState[]>(state => state.tokenReducer)
    const activityStates = useSelector<AppState, ActivityState[]>(state => state.activityReducer)
    const templateState = useSelector<AppState, TemplateNode[]>(state => state.templateReducer)

    const selectedActivity = activityStates.find(s => s.isSelected)
    const focusedToken = tokenStates.find(t => t.hasFocus)

    const contentTypeString = (): string => {
        const contentType = selectedContent?.type

        switch (contentType) {
            case ContentType.DESCRIPTION:
                return "Description"
            case ContentType.EXCERPT:
                return "Extrait"
            case ContentType.TITLE:
                return "Titre"

            default:
                return "Titre"
        }
    }

    if (error) {
        dispatch(setErrorMessage(error.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    useEffect(() => {
        if (contents !== null && selectedContent !== null) {
            const content = contents!.find(c => c.id === selectedContent.id)

            if (content !== undefined) {
                dispatch(setContent(content))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contents, selectedContent])

    useEffect(() => {
        const tokenRegex = /(\[\d+\]|\[\d+,\d{1}\]|{pro_in}|{pro_as}|{geo}|<br\/>)/gm
        const matches = selectedContent?.template.matchAll(tokenRegex)

        if (matches === undefined) return

        let tokenCount: number = 0
        let cursor: number = 0
        const nodes: TemplateNode[] = []
        for (const match of matches) {
            const { index, input } = match
            const rawValue = match[0]

            let tokenId: number | null = null
            let tagId: number | null = null

            // push a non tokenizable node, i.e. the text between tokens
            if (index !== undefined && index > cursor) {
                const value = input?.substring(cursor, index)
                if (value !== undefined) {
                    nodes.push({
                        tokenId,
                        tagId,
                        key: `text_${tokenCount}`,
                        rawValue: value,
                        displayedValue: value,
                        isTokenizable: false,
                        isBr: false
                    })
                }

                cursor = index
            }

            // push the token
            let displayedValue = rawValue
            if (rawValue.charAt(0) === "{") {
                // it's an activity token
                const proKeyRegex = /\w+/g
                const proKeyMatches = proKeyRegex.exec(rawValue)
                if (proKeyMatches !== null && proKeyMatches.length > 0 && selectedActivity !== undefined) {
                    const proKey = proKeyMatches[0]
                    if (proKey === "pro_in") {
                        displayedValue = selectedActivity.value.in
                    } else if (proKey === "pro_as") {
                        displayedValue = selectedActivity.value.as
                    }
                }
            } else if (rawValue.charAt(0) === "[") {
                // it's a text token
                const txtKeyRegex = /(\d+),?(\d)?/g
                const txtKeyMatches = txtKeyRegex.exec(rawValue)
                if (txtKeyMatches !== null && txtKeyMatches.length > 1) {
                    tokenId = parseInt(txtKeyMatches[1])
                    if (txtKeyMatches.length > 2) {
                        tagId = parseInt(txtKeyMatches[2])
                    }

                    const tokenState = tokenStates.find(s => s.value.id === tokenId)
                    if (tokenState !== undefined) {
                        displayedValue = tokenState.value.value
                    }
                }
            } else if (rawValue === "<br/>") {
                nodes.push({
                    tokenId,
                    tagId,
                    key: `token_${tokenCount}_br`,
                    rawValue,
                    displayedValue,
                    isTokenizable: false,
                    isBr: true
                })

                cursor += rawValue.length
                ++tokenCount

                continue
            }

            nodes.push({
                tokenId,
                tagId,
                key: `token_${tokenCount}`,
                rawValue,
                displayedValue,
                isTokenizable: true,
                isBr: false
            })

            cursor += rawValue.length
            ++tokenCount
        }

        dispatch(setTemplate(nodes))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContent, selectedActivity, tokenStates])

    return (
        <Box sx={{ p: 3, borderRadius: 3, bgcolor: "#fbfbff", width: "33%", minWidth: "250px" }}>
            <Typography variant="h6" sx={{ pb: 2 }}>
                {contentTypeString()}
            </Typography>
            <Box
                sx={{
                    "overflowY": "auto",
                    "height": "calc(100% - 70px)",
                    "pr": 2,
                    "&::-webkit-scrollbar": {
                        width: "0.4em"
                    },
                    "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px #f6f6f6",
                        webkitBoxShadow: "inset 0 0 6px #f6f6f6"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#1ab6ff",
                        borderRadius: "1rem"
                    }
                }}
            >
                {templateState.map(n => {
                    let color = null
                    if (n.tagId !== null) {
                        const tag = tags?.find(t => t.id === n.tagId)
                        color = tag?.color
                    }

                    let tokenValue: string = n.rawValue
                    if (n.isTokenizable) {
                        tokenValue = n.displayedValue!
                    } else if (n.isBr) {
                        tokenValue = ""
                    }

                    return (
                        <Typography
                            key={n.key}
                            component={n.isBr ? "p" : "span"}
                            sx={{
                                fontSize: "inherit",
                                color,
                                pb: n.isBr ? 2 : 0,
                                textDecoration:
                                    focusedToken !== undefined && focusedToken.value.id === n.tokenId
                                        ? "underline"
                                        : "none"
                            }}
                        >
                            {tokenValue}
                        </Typography>
                    )
                })}
                .
            </Box>
        </Box>
    )
}

export default ArticleTemplate
