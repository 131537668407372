import { useEffect, useState } from "react";
import StalactiteError from "../common/entities/StalactiteError";
import LocalStorage from "./LocalStorage";
import { API_URL } from "../common/env";

type State<T> = {
    data: T | null
    isLoading: boolean
    error: Error | null
}

export function useApi<T>(url: string): State<T> {
    const [state, setState] = useState<State<T>>({ data: null, isLoading: true, error: null })
    const ls = new LocalStorage()

    useEffect(() => {
        async function fetchData() {
            try {
                const stalToken = ls.get<string>("STAL_TOKEN", false)

                if (stalToken === undefined) {
                    setState({
                        data: null,
                        isLoading: false,
                        error: new Error("STAL_TOKEN is undefined")
                    })
                    return
                }

                const response = await fetch(`${API_URL}/api/v1/${url}`, {
                    headers: { "X-API-TOKEN": stalToken! }
                })

                if (!response.ok) {
                    setState({
                        data: null,
                        isLoading: false,
                        error: new StalactiteError(response.statusText)
                    })
                    return
                }
                const data = await response.json()
                setState({ data, isLoading: false, error: null })
            } catch (err) {
                setState({ data: null, isLoading: false, error: err instanceof Error ? err : null })
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return state
}
