import { Box } from "@mui/material"
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import jwt_decode, { JwtPayload } from "jwt-decode"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { TokensApi } from "stalactite-auth-client"
import { UsersMeApi } from "stalactite-data-client"
import { setIsActive, setIsSuccess, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import AuthenticationError from "../common/entities/AuthenticationError"
import StalactiteError from "../common/entities/StalactiteError"
import LocalStorage from "../features/LocalStorage"
import { AppState } from "../features/store"
import { isLogged, saveCurrentUser } from "./state/userReducer"

const Login: React.FC = () => {
    const isLoggedIn: boolean = useSelector<AppState, boolean>(state => state.userReducer.isLogged)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const errorMessage = "Une erreur est survenue lors de la connexion"
    const ls = new LocalStorage()

    async function onGoogleSignIn(googleUser: CredentialResponse): Promise<void> {
        const googleIdToken = googleUser.credential
        let response

        if (typeof googleIdToken === "string") {
            try {
                response = await signInWithStalactite(googleIdToken)
            } catch (err: unknown) {
                dispatch(setIsActive(true))
                dispatch(setErrorMessage(errorMessage))
                dispatch(setStatus("Erreur"))
                dispatch(setIsSuccess(false))
                throw new StalactiteError()
            }
        }

        if (typeof response === "string") await logUser(response)
    }

    async function signInWithStalactite(googleToken: string): Promise<string> {
        const data = {
            token: googleToken,
            app: "jalis_spinning"
        }

        const API: TokensApi = new TokensApi()

        let res

        try {
            res = await API.login(data)
        } catch (err: unknown) {
            dispatch(setIsActive(true))
            dispatch(setErrorMessage(errorMessage))
            throw new StalactiteError()
        }

        return res.data.token
    }

    const logUser = async (stalToken: string): Promise<void> => {
        const API: UsersMeApi = new UsersMeApi({
            accessToken: undefined,
            baseOptions: undefined,
            basePath: "",
            formDataCtor: undefined,
            password: "",
            username: "",
            isJsonMime(_: string): boolean {
                return false
            },
            apiKey: stalToken
        })

        let user
        let post
        let lead

        try {
            user = await API.getMeAsUser()
            post = await API.getMyPosts()
            lead = await API.getMyLeads()
        } catch (e: unknown) {
            dispatch(setIsActive(true))
            dispatch(
                setErrorMessage("Une erreur est survenu lors de la récupération de l'utilisateur")
            )
            throw new AuthenticationError(
                "Une erreur est survenu lors de la récupération de l'utilisateur"
            )
        }

        const jwt: JwtPayload = jwt_decode(stalToken)
        const userPost = {
            ...user.data,
            post: { ...post.data[0] },
            lead: { ...lead.data[0] },
            exp: jwt.exp,
            iat: jwt.iat
        }

        ls.add("STAL_USER", userPost)
        ls.add("STAL_TOKEN", stalToken, false)

        dispatch(saveCurrentUser(userPost))
        dispatch(isLogged(true))

        navigate("/")
    }

    return (
        <>
            {!isLoggedIn && (
                <GoogleOAuthProvider clientId="694442042963-ppr2ro8vg8suaged73eauka553pdevva.apps.googleusercontent.com">
                    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
                        <Box display="flex" flexWrap="wrap" width="400px">
                            <Box width="100%" display="flex" justifyContent="center">
                                <GoogleLogin
                                    theme="outline"
                                    size="large"
                                    type="standard"
                                    shape="rectangular"
                                    logo_alignment="left"
                                    onSuccess={onGoogleSignIn}
                                    onError={() => {
                                        console.log("Login Failed")
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </GoogleOAuthProvider>
            )}
        </>
    )
}

export default Login
