export default class LocalStorage {

    private localStorage: Storage

    constructor() {
        this.localStorage = window.localStorage
    }

    add(key: string, data: any, stringify = true): void {

        if (!stringify) {
            return this.localStorage.setItem(key, data)
        }

        return this.localStorage.setItem(key, JSON.stringify(data))
    }

    get<T>(key: string, parse: boolean = true): T | undefined {
        const data = this.localStorage.getItem(key)
        if (data === null) return undefined

        return parse ? JSON.parse(data) : data
    }

    remove(key: string): void {
        this.localStorage.removeItem(key)
    }

    clear(): void {
        this.localStorage.clear()
    }
}
