import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Activity } from "@spinning/entities"

export type ActivityState = {
    value: Activity
    isSelected: boolean
}

const defaultState: ActivityState = {
    value: { id: 0, value: "Tous", in: "{pro_in}", as: "{pro_as}" },
    isSelected: true
}

const activityReducer = createSlice({
    name: "activity",
    initialState: [defaultState] as ActivityState[],
    reducers: {
        setActivities: (_, { payload: activities }: PayloadAction<Activity[]>) => {
            return [
                defaultState,
                ...activities.map(a => {
                    return { value: a, isSelected: false }
                })
            ]
        },

        setSelectedActivity: (state, { payload: activity }: PayloadAction<Activity | undefined>) => {
            if (activity === undefined) {
                return [...state]
            }
            return state.map(a => {
                return { ...a, isSelected: a.value.id === activity.id }
            })
        }
    }
})

export const { setActivities, setSelectedActivity } = activityReducer.actions
export default activityReducer.reducer
