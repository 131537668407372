import { configureStore } from "@reduxjs/toolkit"
import { Tag } from "../../../common/entities/Tag"
import activityReducer, { ActivityState } from "../article/state/activityReducer"
import articleReducer from "../article/state/articleReducer"
import contentReducer, { ContentState } from "../article/state/contentReducer"
import tagsReducer from "../article/state/tagsReducer"
import templateReducer, { TemplateNode } from "../article/state/templateReducer"
import tokenReducer, { TokenState } from "../article/state/tokenReducer"
import alertReducer from "../common/components/state/alertReducer"
import errorReducer from "../common/components/state/errorReducer"
import userReducer from "../login/state/userReducer"

export type AppState = {
    errorReducer: {
        errorMessage: string
    }
    articleReducer: {
        article: {}
    }
    userReducer: {
        user: {}
        isLogged: boolean
    }
    contentReducer: ContentState
    activityReducer: ActivityState[]
    alertReducer: {
        status: ""
        isActive: false
        isSuccess: false
    }
    tagsReducer: Tag[]
    tokenReducer: TokenState[]
    templateReducer: TemplateNode[]
}

export default configureStore({
    reducer: {
        errorReducer,
        articleReducer,
        userReducer,
        contentReducer,
        activityReducer,
        alertReducer,
        tagsReducer,
        templateReducer,
        tokenReducer,
    }
})
