import { Box, Toolbar, useMediaQuery } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { useTheme } from "@mui/material/styles"
import { googleLogout } from "@react-oauth/google"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import LocalStorage from "../../features/LocalStorage"
import { AppState } from "../../features/store"
import { isLogged, removeCurrentUser } from "../../login/state/userReducer"
import NavBar from "../../navbar/NavBar"

type Props = { children: React.ReactNode }

const Layout: React.FC<Props> = ({ children }) => {
    const user = useSelector<AppState, any>(state => state.userReducer.user)
    const [isEasterEggActive, setIsEasterEggActive] = useState(false)
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up("md"))

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ls = new LocalStorage()

    const isTokenValid = (): boolean =>
        Date.now() / 1000 < user.exp && new Date().setHours(0, 0, 0, 0) / 1000 < user.iat

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.altKey && event.ctrlKey && event.key === "n") {
            setIsEasterEggActive(!isEasterEggActive)
            navigate("/troliloliflex")
        }
    }

    useEffect(() => {
        if (!isTokenValid()) {
            ls.clear()
            dispatch(removeCurrentUser())
            dispatch(isLogged(false))
            googleLogout()
            navigate("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            <CssBaseline />
            <NavBar isLarge={isLarge} />
            <Box component="main" sx={{ pt: 10, mx: isLarge ? 10 : 1, width: 1 }}>
                <Toolbar />
                {children}
            </Box>
        </Box>
    )
}

export default Layout
