import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Tag } from "@spinning/entities"

const tagsReducer = createSlice({
    name: "token",
    initialState: [] as Tag[],

    reducers: {
        setTags: (_, { payload: tags }: PayloadAction<Tag[]>) => tags
    }
})

export const {
    setTags,
} = tagsReducer.actions
export default tagsReducer.reducer
