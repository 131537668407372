import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Article, Content } from "@spinning/entities"
import { ContentType } from "../../features/ContentType"
import { TokenState } from "./tokenReducer"

export type ContentState = {
    value: Content | null
    tokens: TokenState[] | null
}

const contentReducer = createSlice({
    name: "content",
    initialState: { value: null, tokens: null } as ContentState,

    reducers: {
        setContentFromTypeAndArticle: (
            state,
            { payload: cta }: PayloadAction<{ ct: ContentType; a: Article | null }>
        ) => {
            if (cta.a === null) return state

            const content = cta.a.contents.find(c => c.type === cta.ct)
            return {
                value: content === undefined ? null : content,
                activity: null,
                tokens: null
            }
        },

        setContent: (_, { payload: content }: PayloadAction<Content>) => {
            if (content.tokens === undefined) {
                return {
                    value: content,
                    activity: null,
                    tokens: null
                }
            }
            return {
                value: content,
                activity: null,
                tokens: content.tokens.map(t => {
                    return { value: t, hasFocus: false, hasError: false }
                })
            }
        }
    }
})

export const { setContent, setContentFromTypeAndArticle } = contentReducer.actions
export default contentReducer.reducer
