import { useSelector } from "react-redux"
import "./App.css"
import Alert from "./common/components/Alert"
import Navigation from "./common/components/Navigation"
import { AppState } from "./features/store"

const App = () => {
    const isActiveAlert = useSelector<AppState, boolean>(state => state.alertReducer.isActive)

    const status = useSelector<AppState, string>(state => state.alertReducer.status)
    const isSuccess = useSelector<AppState, boolean>(state => state.alertReducer.isSuccess)
    const errorMessage = useSelector<AppState, string>(state => state.errorReducer.errorMessage)

    return (
        <>
            {isActiveAlert && (
                <Alert showError={isActiveAlert} message={errorMessage} alertType={status} success={isSuccess} />
            )}
            <Navigation />
        </>
    )
}

export default App