import { Box } from "@mui/system"
import React from "react"
import Layout from "./Layout"

type CenterProps = { children: React.ReactNode }

const Center: React.FC<CenterProps> = ({ children }) => (
    <Layout>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            {children}
        </Box>
    </Layout>
)

export default Center