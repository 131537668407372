import { Box } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import Swal from "sweetalert2"
import { setIsActive } from "./state/alertReducer"
import { setErrorMessage } from "./state/errorReducer"

type Props = { message?: String; showError: boolean; alertType: string; success?: boolean }

const Alert: React.FC<Props> = ({ showError, message, alertType, success = false }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (showError) {
            Swal.fire({
                title: alertType,
                text: `${message}`,
                icon: !success ? "error" : "success",
                confirmButtonText: "Retour"
            })
        }

        dispatch(setIsActive(false))
        dispatch(setErrorMessage(""))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Box id="error"></Box>
        </>
    )
}

export default Alert
