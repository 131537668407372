import { ThemeProvider } from "@mui/material"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import store from "./features/store"
import "./index.css"
import theme from "./theme"

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>
)
