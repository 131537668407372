import { Box, Card, CardContent, CardHeader, useMediaQuery } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useTheme } from "@mui/material/styles"
import { Activity, Content, Article as SelectedArticle, Tag, Token } from "@spinning/entities"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Layout from "../common/components/Layout"
import { setIsActive, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import { ContentType } from "../features/ContentType"
import { useApi } from "../features/UseApi"
import { AppState } from "../features/store"
import Loader from "../loader/Loader"
import ArticleTemplate from "./ArticleTemplate"
import ArticleTokens from "./ArticleTokens"
import { setActivities } from "./state/activityReducer"
import { setContentFromTypeAndArticle } from "./state/contentReducer"
import { setTags } from "./state/tagsReducer"
import { setTokens } from "./state/tokenReducer"
import { API_URL } from "../common/env"

const Article = React.memo(() => {
    const { articleId } = useParams<{ articleId: string }>()
    const dispatch = useDispatch()

    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up("md"))

    const selectedContent = useSelector<AppState, Content | null>(state => state.contentReducer.value)

    const {
        data: activities,
        isLoading: isLoadingActivities,
        error: activitiesError
    } = useApi<Activity[]>("activities")
    if (activitiesError) {
        dispatch(setErrorMessage(activitiesError.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    const { data: article, error: articleError } = useApi<SelectedArticle>(`articles/${articleId}`)
    if (articleError !== null) {
        dispatch(setErrorMessage(articleError.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    const { data: contentTypes, isLoading: isLoadingContentTypes, error: contentTypesError } = useApi("contents/types")
    if (contentTypesError) {
        dispatch(setErrorMessage(contentTypesError.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    const { data: tags, isLoading: isLoadingTags, error: tagsError } = useApi<Tag[]>("tags")
    if (tagsError) {
        dispatch(setErrorMessage(tagsError.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    const { data: tokens, isLoading: isLoadingTokens, error: tokensError } = useApi<Token[]>("tokens")
    if (tokensError) {
        dispatch(setErrorMessage(tokensError.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
    }

    useEffect(() => {
        if (activities !== null) {
            dispatch(setActivities(activities))
        }

        if (article !== null) {
            console.log(`API_URL is: ${API_URL}`)
            dispatch(setContentFromTypeAndArticle({ a: article, ct: ContentType.TITLE }))
        }

        if (tags !== null) {
            dispatch(setTags(tags))
        }

        if (tokens !== null) {
            dispatch(setTokens(tokens))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities, article, tags, tokens])

    return (
        <Layout>
            <Card
                sx={{
                    borderRadius: 3,
                    height: `calc(100vh - ${isLarge ? 180 : 150}px)`,
                    mb: 10,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
                elevation={8}
                square={false}
            >
                <CardHeader
                    sx={{ display: "block", pb: "2px" }}
                    action={
                        <Box sx={{ width: isLarge ? "50%" : "100%" }}>
                            {isLoadingActivities || isLoadingContentTypes || isLoadingTags || isLoadingTokens ? (
                                <Loader size={20} />
                            ) : (
                                <Tabs
                                    value={selectedContent?.type || ContentType.TITLE}
                                    onChange={(_, newContentType: ContentType) => {
                                        dispatch(setContentFromTypeAndArticle({ ct: newContentType, a: article }))
                                    }}
                                    sx={{ color: "primary" }}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                >
                                    {Object.entries(contentTypes || {}).map(contentType => {
                                        const contentTypeValue = contentType[0].toLowerCase()
                                        const contentTypeValueTranslated = contentType[1]

                                        return (
                                            <Tab
                                                key={contentTypeValue}
                                                value={contentTypeValue}
                                                label={contentTypeValueTranslated}
                                                sx={{ padding: "0 10px", color: "primary" }}
                                            />
                                        )
                                    })}
                                </Tabs>
                            )}
                        </Box>
                    }
                ></CardHeader>
                <Box sx={{ backgroundColor: "#f6f6f6" }} position="relative" height="2px" />
                <CardContent sx={{ display: "flex", height: 1 }}>
                    <ArticleTemplate tags={tags} />
                    <ArticleTokens tags={tags} />
                </CardContent>
            </Card>
        </Layout>
    )
})

export default Article
