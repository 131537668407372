import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TemplateNode = {
    tokenId: number | null
    tagId: number | null
    key: string
    rawValue: string
    displayedValue: string | null
    isTokenizable: boolean
    isBr: boolean
}

const templateReducer = createSlice({
    name: "template",
    initialState: [] as TemplateNode[],

    reducers: {
        setTemplate: (_, { payload: template }: PayloadAction<TemplateNode[]>) => template
    }
})

export const { setTemplate } = templateReducer.actions
export default templateReducer.reducer