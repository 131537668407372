import { createSlice } from '@reduxjs/toolkit'

const errorReducer = createSlice({
    name: 'error',
    initialState: {
        errorMessage: '',
    },
    reducers: {
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
    },
})

export const { setErrorMessage } = errorReducer.actions
export default errorReducer.reducer
