import { Box, FormControl, Grid, MenuItem } from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { Tag } from "@spinning/entities"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "../features/store"
import ArticleTokensInputs from "./ArticleTokensInputs"
import { ActivityState, setSelectedActivity } from "./state/activityReducer"

type Props = { tags: Tag[] | null }

const ArticleTokens: React.FC<Props> = ({ tags }) => {
    const activitiesStates = useSelector<AppState, ActivityState[]>(state => state.activityReducer)
    const selectedActivity = activitiesStates.find(a => a.isSelected)

    const dispatch = useDispatch()

    return (
        <Box width="100%">
            <Grid item xs={4}>
                <FormControl sx={{ pl: 2, pb: 2 }}>
                    <Select
                        onChange={(e: SelectChangeEvent) => {
                            const id = parseInt(e.target.value)
                            if (isNaN(id)) return

                            const selectedActivity = activitiesStates.find(a => a.value.id === id)?.value
                            dispatch(setSelectedActivity(selectedActivity))
                        }}
                        value={`${selectedActivity?.value?.id || 0}`}
                        size="small"
                        variant="outlined"
                    >
                        {activitiesStates?.map(state => {
                            const { value } = state
                            return (
                                <MenuItem value={value.id} key={value.id}>
                                    {value.value}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <ArticleTokensInputs />
        </Box>
    )
}

export default ArticleTokens
