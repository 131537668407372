import { Autocomplete, Box, Grid, IconButton, TextField, Typography, useMediaQuery } from "@mui/material"

import {
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    createFilterOptions
} from "@mui/material/Autocomplete"

import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/material/styles"
import { Variant } from "@spinning/entities"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIsActive, setIsSuccess, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import { API_URL } from "../common/env"
import LocalStorage from "../features/LocalStorage"
import { AppState } from "../features/store"
import {
    TokenState,
    VariantState,
    addToken,
    addVariant,
    deleteVariant,
    setTokenHasError,
    setTokenHasFocus,
    setVariants
} from "./state/tokenReducer"
import { TemplateNode } from "./state/templateReducer"

const filterVariants = createFilterOptions<VariantState>()

const ArticleTokensInputs: React.FC = () => {
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up("md"))

    const [isLoading, setIsLoading] = useState(false)
    const contentTokens = useSelector<AppState, TokenState[] | null>(state => state.contentReducer.tokens)
    const tokenStates = useSelector<AppState, TokenState[] | null>(state => state.tokenReducer)
    const templateState = useSelector<AppState, TemplateNode[]>(state => state.templateReducer)

    const focusedToken = tokenStates?.find(t => t.hasFocus)
    const errorToken = tokenStates?.find(t => t.hasError)

    const ls = new LocalStorage()
    const stalToken = ls.get<string>("STAL_TOKEN", false)

    const dispatch = useDispatch()

    const dispatchErrorAction = (statusText: string): void => {
        dispatch(setErrorMessage(statusText))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
        dispatch(setIsSuccess(false))
        return
    }

    const dispatchSuccesAction = (message: string): void => {
        dispatch(setIsActive(true))
        dispatch(setStatus(message))
        dispatch(setIsSuccess(true))
    }

    const fetchVariants = async (tokenId: number | null) => {
        if (tokenStates === null || tokenId === null) return

        const tokenState = tokenStates.find(t => t.value.id === tokenId)

        if (tokenState === undefined) {
            const contentToken = contentTokens?.find(t => t.value.id === tokenId)
            dispatch(addToken(contentToken?.value))
        } else if (!tokenState.hasFocus) {
            dispatch(setTokenHasFocus(tokenState.value))
        }
    }

    const saveVariant = async (variantValue: string) => {
        if (focusedToken?.value.variants.find(v => v.value === variantValue) !== undefined) {
            dispatch(setTokenHasError(focusedToken?.value))
            return
        }

        const response = await fetch(`${API_URL}/api/v1/variants`, {
            method: "post",
            headers: { "X-API-TOKEN": stalToken!, "Content-Type": "application/json" },
            body: JSON.stringify({ tokenId: focusedToken?.value.id, value: variantValue })
        })

        const tokenResponse = await response.json()
        const newTokenId = tokenResponse.identifiers[0].id

        if (response.status !== 201) {
            dispatchErrorAction(response.statusText)
        }

        dispatch(addVariant({ id: newTokenId, token: focusedToken?.value, value: variantValue }))
        dispatchSuccesAction("Ajout effectué")
    }

    const removeVariant = async (variant: Variant) => {
        const response = await fetch(`${API_URL}/api/v1/variants/${variant.id}`, {
            method: "delete",
            headers: { "X-API-TOKEN": stalToken! }
        })

        if (response.status !== 200) {
            dispatchErrorAction(response.statusText)
        }

        dispatch(deleteVariant(variant))
        dispatchSuccesAction("Suppression effectué")
    }

    // when a token has been given focus, we check for variants
    useEffect(() => {
        const loadVariants = async () => {
            if (focusedToken === undefined || focusedToken.value.variants !== undefined) return
            const { id: tokenId } = focusedToken.value

            setIsLoading(true)
            const response = await fetch(`${API_URL}/api/v1/tokens/${tokenId}`, {
                method: "get",
                headers: { "X-API-TOKEN": stalToken! }
            })

            const token = await response.json()
            const variants: Variant[] = []

            for (const variant of token.variants) {
                variants.push(variant as Variant)
            }

            setIsLoading(false)
            dispatch(setVariants({ ...focusedToken.value, variants }))
        }

        loadVariants()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedToken?.value.id])

    useEffect(() => {
        contentTokens?.forEach(t => fetchVariants(t.value.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentTokens])

    return (
        <Box
            sx={{
                "height": "calc(100% - 110px)",
                "overflowY": "auto",
                "&::-webkit-scrollbar": {
                    width: "0.4em"
                },
                "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px #f6f6f6",
                    webkitBoxShadow: "inset 0 0 6px #f6f6f6"
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1ab6ff",
                    borderRadius: "1rem"
                }
            }}
        >
            <Grid container spacing={2} sx={{ p: 2 }}>
                {templateState
                    ?.filter(e => e.tokenId !== null)
                    .map((node, i) => {
                        const { tokenId, tagId } = node

                        const token = tokenStates?.find(t => t.value.id === tokenId)?.value
                        const color = token?.tag?.color

                        return (
                            <Grid
                                item
                                xs={isLarge ? 4 : 12}
                                key={`${tokenId}-${i}`}
                                sx={{ padding: "20px", boxSizing: "border-box" }}
                            >
                                <Autocomplete
                                    id={`token_input_${tokenId}-${tagId}`}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    freeSolo
                                    getOptionLabel={option => {
                                        if (typeof option === "string") {
                                            return option
                                        } else if (option.input) {
                                            return option.input
                                        }
                                        return option.value.value
                                    }}
                                    options={
                                        (focusedToken?.value?.variants || ([] as Variant[])).map(value => {
                                            return { value }
                                        }) as VariantState[]
                                    }
                                    loading={isLoading}
                                    onChange={(
                                        e,
                                        value: string | VariantState | null,
                                        reason: AutocompleteChangeReason
                                    ) => {
                                        if (reason === "createOption" || reason === "selectOption") {
                                            e.stopPropagation()
                                        }

                                        if (typeof value === "string") {
                                            saveVariant(value)
                                        } else if (value?.input) {
                                            saveVariant(value.value.value)
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filteredVariants = filterVariants(options, params)
                                        const { inputValue } = params

                                        const isExisting = options.some(option => inputValue === option.value.value)
                                        if (inputValue !== "" && !isExisting) {
                                            filteredVariants.push({
                                                value: { id: -1, value: inputValue },
                                                input: `Ajouter "${inputValue}"`
                                            })
                                        }

                                        return filteredVariants
                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <TextField
                                            {...params}
                                            fullWidth={true}
                                            label={token?.value}
                                            size="small"
                                            id={`variant_${tokenId}`}
                                            variant="outlined"
                                            error={errorToken !== undefined}
                                            helperText={errorToken !== undefined ? "Champ déjà enregistré" : ""}
                                            onFocus={() => fetchVariants(tokenId)}
                                            multiline
                                            sx={{
                                                label: {
                                                    color: `${color}!important`
                                                },
                                                fieldset: {
                                                    borderColor: `${color}!important`
                                                }
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography sx={{ fontSize: "12px" }}>
                                                    {option.input === undefined ? option.value.value : option.input}
                                                </Typography>
                                            </Box>
                                            {option.input === undefined && (
                                                <IconButton
                                                    color="error"
                                                    component="label"
                                                    size="small"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        removeVariant(option.value)
                                                    }}
                                                >
                                                    <CloseIcon sx={{ fontSize: "19px" }} />
                                                </IconButton>
                                            )}
                                        </li>
                                    )}
                                />
                            </Grid>
                        )
                    })}
            </Grid>
        </Box>
    )
}

export default ArticleTokensInputs
