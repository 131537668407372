import { createSlice } from '@reduxjs/toolkit'

const articleReducer = createSlice({
    name: 'article',
    initialState: {
        article: {}
    },
    reducers: {
        setArticle: (state, action) => {
            state.article = action.payload
        },
    },
})

export const { setArticle } = articleReducer.actions
export default articleReducer.reducer
