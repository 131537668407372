import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import NavBarLeftContainer from "./NavBarLeftContainer"
import NavBarRightContainer from "./NavBarRightContainer"

type NavBarProps = { isLarge: boolean }
const NavBar: React.FC<NavBarProps> = ({ isLarge }) => {
    return (
        <AppBar component="nav" sx={{ backgroundColor: "transparent", boxShadow: "none", px: isLarge ? 10 : 1 }}>
            <Toolbar sx={{ flexGrow: 1, p: "0 !important", my: 5, justifyContent: "space-between" }}>
                <NavBarLeftContainer />
                <NavBarRightContainer isLarge={isLarge} />
            </Toolbar>
        </AppBar>
    )
}

export default NavBar
