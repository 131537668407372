import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#1ab6ff'
        },
        secondary:{
            main: 'rgba(0, 0, 0, 0.6)'
        },
    },

    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    width: '50%',
                    zIndex: '10',
                }
            },
        },
        MuiButtonGroup: {
            defaultProps:{
                color: 'secondary'
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    color: '#1ab6ff'
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                colorPrimary: {
                    color: 'white',
                    backgroundColor: '#1ab6ff'
                }
            }
        },
    }
})

export default theme
