import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Token, Variant } from "@spinning/entities"

export type TokenState = {
    value: Token
    hasFocus: boolean
    hasError: boolean
    variantInput?: string
}

export type VariantState = {
    value: Variant
    input?: string
}

const tokenReducer = createSlice({
    name: "token",
    initialState: [] as TokenState[],

    reducers: {
        setVariants: (state, { payload: tokenWithVariants }: PayloadAction<Token>) => {
            const newState = [...state]
            for (let i = 0; i < state.length; ++i) {
                const tokenState = state[i]
                if (tokenState.value.id === tokenWithVariants.id) {
                    newState.splice(i, 1, { ...tokenState, value: { ...tokenWithVariants } })
                }
            }
            return newState
        },

        setTokenHasFocus: (state, { payload: token }: PayloadAction<Token | undefined>) => {
            if (token === undefined) {
                return [...state]
            }

            return state.map(t => {
                return { ...t, hasFocus: t.value.id === token.id }
            })
        },

        setTokenVariantInput: (state, { payload }: PayloadAction<{ id: number; input: string }>) => {
            return state.map(t => {
                return { ...t, variantInput: t.value.id === payload.id ? payload.input : t.variantInput }
            })
        },

        setTokenHasError: (state, { payload: token }: PayloadAction<Token | undefined>) => {
            if (token === undefined) {
                return [...state]
            }

            return state.map(t => {
                return { ...t, hasError: t.value.id === token.id }
            })
        },

        addError: (state, { payload: token }: PayloadAction<Token | undefined>) => {
            if (token === undefined) {
                return [...state]
            }

            const newState = [...state]

            for (let i = 0; i < state.length; ++i) {
                const tokenState = state[i]
                if (tokenState.value.id === token.id) {
                    newState.splice(i, 1, {
                        value: token,
                        hasError: true,
                        hasFocus: tokenState.hasFocus
                    })
                }
            }

            return newState
        },

        addToken: (state, { payload: token }: PayloadAction<Token | undefined>) => {
            const newState = [...state]
            if (token === undefined) {
                return newState
            }

            if (state.findIndex(t => t.value.id === token.id) === -1) {
                newState.push({ value: token, hasFocus: false, hasError: false })
            }

            return newState.map(t => {
                return { value: t.value, hasFocus: t.value.id === token.id, hasError: false }
            })
        },

        setTokens: (state, { payload: tokens }: PayloadAction<Token[] | undefined>) => {
            if (tokens === undefined) return state

            return tokens.map(t => {
                return { value: t, hasFocus: false, hasError: false }
            })
        },

        addVariant: (state, { payload: variant }: PayloadAction<Variant>) => {
            if (variant === undefined) return state

            for (const t of state) {
                if (t.value.id === variant?.token?.id) {
                    t.value.variants.push(variant)
                    t.hasError = false
                }
            }
        },

        deleteVariant: (state, { payload: variant }: PayloadAction<Variant>) => {
            if (variant === undefined) return state

            let i = 0
            outerloop: for (const t of state) {
                let j = 0

                for (const v of t.value.variants) {
                    if (v.id === variant.id) {
                        state[i].value.variants.splice(j, 1)
                        break outerloop
                    }
                    j++
                }
                i++
            }
        }
    }
})

export const {
    setTokenHasFocus,
    setTokenHasError,
    addToken,
    setTokens,
    setVariants,
    deleteVariant,
    addVariant,
    setTokenVariantInput
} = tokenReducer.actions
export default tokenReducer.reducer
