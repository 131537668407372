import { Box, Grid } from "@mui/material"
import { Article } from "@spinning/entities"
import { useDispatch } from "react-redux"
import ArticleCard from "../article/ArticleCard"
import Layout from "../common/components/Layout"
import { setIsActive, setIsSuccess, setStatus } from "../common/components/state/alertReducer"
import { setErrorMessage } from "../common/components/state/errorReducer"
import { useApi } from "../features/UseApi"
import Loader from "../loader/Loader"

const Home = () => {
    const { data, error } = useApi<Article[]>("articles")
    const dispatch = useDispatch()
    const isLoading = false

    if (error) {
        dispatch(setErrorMessage(error.message))
        dispatch(setIsActive(true))
        dispatch(setStatus("Erreur"))
        dispatch(setIsSuccess(false))
    }

    return (
        <Layout>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Loader size={40} />
                </Box>
            ) : (
                <Grid container spacing={4} sx={{ flexWrap: "wrap" }}>
                    <ArticleCard articles={data} />
                </Grid>
            )}
        </Layout>
    )
}

export default Home
