import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Center from "../common/components/Center"

const MyComponent = () => {

    const [imageName , setImageName] = useState('')

    const randomImg = (): string => {
        const files = [
            'joachim-removebg-preview.png',
            'julien-removebg-preview.png',
            'laura-removebg-preview.png',
            'tetu-removebg-preview.png'
        ];
        const randomIndex = Math.floor(Math.random() * files.length);
        const randomFile = files[randomIndex];
        return `./img/${randomFile}`;
    };

    useEffect(() => {
        setImageName(randomImg())
    }, [])

    return (
        <Center>
            <Card sx={{ maxWidth: 600 }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="300"
                        width="100%"
                        image={imageName}
                        src='picture'
                        alt="nathan"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            NATHAN
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            NATHAN est un personnage imaginaire inventé par nos amis les reférenceurs.
                            Voici la tronche des blagueurs
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Center>

    )
}

export default MyComponent
