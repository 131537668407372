import { createSlice } from "@reduxjs/toolkit"

const alertReducer = createSlice({
    name: "error",
    initialState: {
        status: "",
        isActive: false,
        isSuccess: false
    },
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },

        setIsActive: (state, action) => {
            state.isActive = action.payload
        },

        setIsSuccess: (state, action) => {
            state.isSuccess = action.payload
        }
    }
})

export const { setStatus, setIsActive, setIsSuccess } = alertReducer.actions
export default alertReducer.reducer
