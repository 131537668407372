import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import { ButtonGroup, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { googleLogout } from "@react-oauth/google"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import LocalStorage from "../features/LocalStorage"
import { isLogged, removeCurrentUser } from "../login/state/userReducer"

type NavBarRightContainerProps = { isLarge: boolean }

const NavBarRightContainer: React.FC<NavBarRightContainerProps> = ({ isLarge }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ls = new LocalStorage()

    const logout = (): void => {
        ls.clear()
        dispatch(removeCurrentUser())
        dispatch(isLogged(false))
        googleLogout()
        navigate("/login")
    }

    return (
        <Box sx={{ justifyContent: "space-between" }}>
            <ButtonGroup variant="text" sx={{ backgroundColor: "white", py: 1 }}>
                {isLarge && (
                    <Button size="small">
                        <FileCopyIcon sx={{ fontSize: "large", color: "text.secondary" }} />
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            component="a"
                            href="https://docs.google.com/document/d/1utxiX2J851BGjmj_StifWWQEhNbrJpNCFaznQIhMhQ8/edit"
                            sx={{ px: 1, textDecoration: "none" }}
                            target="_blank"
                        >
                            Documentation
                        </Typography>
                    </Button>
                )}
                <Button size="small" onClick={logout}>
                    <ExitToAppIcon sx={{ fontSize: "large", color: "text.secondary" }} />
                    <Typography variant="caption" color="text.secondary" sx={{ px: 1 }}>
                        Se Déconnecter
                    </Typography>
                </Button>
            </ButtonGroup>
        </Box>
    )
}

export default NavBarRightContainer
