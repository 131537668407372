import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Article from "../../article/Article"
import Easter from "../../easter/Easter"
import LocalStorage from "../../features/LocalStorage"
import { AppState } from "../../features/store"
import Home from "../../home/Home"
import Login from "../../login/Login"
import { isLogged, saveCurrentUser } from "../../login/state/userReducer"
import Alert from "./Alert"

const Navigation = () => {
    const ls = new LocalStorage()
    const dispatch = useDispatch()
    const isLoggedIn = useSelector<AppState, boolean>(state => state.userReducer.isLogged)
    const isActiveAlert = useSelector<AppState, boolean>(state => state.alertReducer.isActive)

    useEffect(() => {
        if (ls.get("STAL_USER")) {
            dispatch(isLogged(true))
            dispatch(saveCurrentUser(ls.get("STAL_USER")))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Router>
            <Routes>
                {!isLoggedIn && <Route path={"/login"} element={<Login />} />}
                {isLoggedIn && (
                    <>
                        <Route path="/" element={<Home />} />
                        <Route path="/article/:articleId" element={<Article />} />
                        <Route path="/troliloliflex" element={<Easter />} />
                    </>
                )}
                <Route path={"/"} element={isLoggedIn ? <Navigate to="/" /> : <Navigate to="/login" />} />
                <Route path={"/login"} element={isLoggedIn && <Navigate to="/" />} />
                <Route
                    path="/error"
                    element={<Alert showError={isActiveAlert} message="Forbidden" alertType="Erreur" />}
                />
                <Route
                    path={"*"}
                    element={<Alert showError={isActiveAlert} message="Page introuvable" alertType="Erreur" />}
                />
            </Routes>
        </Router>
    )
}

export default Navigation
